<form nz-form [formGroup]="validateForm" (ngSubmit)="submitForm()" style="margin-top: 15px">
    <nz-form-item>
        <nz-form-label [nzSpan]="4" nzRequired>书吧名称</nz-form-label>
        <nz-form-control [nzSpan]="20" nzHasFeedback nzErrorTip="请输入书吧名称！">
            <input nz-input formControlName="name" placeholder="请输入">
        </nz-form-control>
    </nz-form-item>
    <nz-form-item>
        <nz-form-label [nzSpan]="4" nzRequired>联系电话</nz-form-label>
        <nz-form-control [nzSpan]="20" nzHasFeedback nzErrorTip="请输入正确的联系电话！">
            <input nz-input formControlName="phone" placeholder="请输入">
        </nz-form-control>
    </nz-form-item>
    <nz-form-item>
        <nz-form-label [nzSpan]="4" nzRequired>书吧地址</nz-form-label>
        <nz-form-control [nzSpan]="20" nzHasFeedback nzErrorTip="请输入书吧地址！">
            <textarea formControlName="address" nz-input rows="3" placeholder="请输入"></textarea>
        </nz-form-control>
    </nz-form-item>
    <nz-form-item>
        <nz-form-label [nzSpan]="4">书吧简介</nz-form-label>
    </nz-form-item>
    <nz-form-item>
        <nz-form-label [nzSpan]="4" nzRequired>重点介绍</nz-form-label>
        <nz-form-control [nzSpan]="20" nzHasFeedback nzErrorTip="请输入重点介绍！">
            <textarea formControlName="highlight" nz-input rows="3" placeholder="请输入"></textarea>
        </nz-form-control>
    </nz-form-item>
    <nz-form-item>
        <nz-form-label [nzSpan]="4" nzRequired>更多介绍</nz-form-label>
        <nz-form-control [nzSpan]="20" nzHasFeedback nzErrorTip="请输入更多介绍！">
            <textarea formControlName="introduction" nz-input rows="5" placeholder="请输入"></textarea>
        </nz-form-control>
    </nz-form-item>
    <nz-form-item>
        <nz-form-control [nzOffset]="5" [nzSpan]="18">
            <button nz-button nzBlock nzType="primary" [disabled]="!validateForm.valid">保存</button>
        </nz-form-control>
    </nz-form-item>
</form>
