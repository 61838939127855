import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {BaseService} from './base.service';
import {Router} from '@angular/router';
import {from, Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {BookBarEnrollment} from '../model/BookBarEnrollment';
import {PaginationSearchResult} from '../model/PaginationSearchResult';

@Injectable({
    providedIn: 'root'
})

export class BookBarEnrollmentService extends BaseService {
    constructor(protected override http: HttpClient, protected override router: Router) {
        super(http, router);
    }

    queryByPage(page: number, filters?: { key: string; value: any; }[]): Observable<PaginationSearchResult<BookBarEnrollment>> {
        const searchParams = new URLSearchParams();
        searchParams.append('page', `${page}`);
        filters?.forEach(i => searchParams.append(i.key, i.value));

        return from(super.get(`/book-bar/enrollment?${searchParams.toString()}`))
            .pipe(
                map(result => result as PaginationSearchResult<BookBarEnrollment>)
            );
    }

    markAsTracked(id: number): Observable<void> {
        return from(super.put(`/book-bar/enrollment/tracked/${id}`, null));
    }

}
