import {Component, OnInit, OnDestroy} from '@angular/core';
import {NavigationEnd, Router} from '@angular/router';
import {UserService} from '../../services/user.service';
import {PermissionService} from '../../services/permission.service';
import {Subscription} from 'rxjs';
import {filter} from 'rxjs/operators';

interface MenuItem {
    name: string;
    path: string;
    hidden?: boolean;
    children?: MenuItem[];
    data?: {
        role?: string[];
        type?: string[];
        name?: string;
    };
    url?: string;
    tree?: MenuItem[];
    expanded?: boolean;
    breadcrumb?: string[];
}

interface User {
    type: string;
    school_type: string;
}

@Component({
    selector: 'app-admin',
    templateUrl: './admin.component.html',
    styleUrls: ['./admin.component.scss']
})
export class AdminComponent implements OnInit, OnDestroy {
    tabs: MenuItem[] = [];
    breadcrumb: string[] = ['首页'];
    user: User | null = null;
    private routerSubscription: Subscription | null = null;

    constructor(
        private userService: UserService,
        private router: Router,
        private permissionService: PermissionService
    ) {
        this.user = this.userService.getUserInfo();
        this.initializeTabs();
        this.setupRouterEvents();
    }

    ngOnInit(): void {
    }

    ngOnDestroy(): void {
        if (this.routerSubscription) {
            this.routerSubscription.unsubscribe();
        }
    }

    private initializeTabs(): void {
        const adminRouterConfig = this.findAdminRouterConfig();
        if (adminRouterConfig) {
            this.tabs = this.buildMenu(adminRouterConfig);
        }
    }

    private findAdminRouterConfig(): any {
        return this.router.config.find(item => item.path === 'admin');
    }

    private setupRouterEvents(): void {
        this.routerSubscription = this.router.events.pipe(
            filter(event => event instanceof NavigationEnd)
        ).subscribe(event => {
            this.updateExpandedMenus();
            this.updateBreadcrumb((event as NavigationEnd).urlAfterRedirects);
        });
    }

    private updateExpandedMenus(): void {
        this.tabs
            .filter(tab => tab.tree?.length)
            .forEach(tab => {
                tab.expanded = tab.tree!.some(sub => this.router.url === sub.url);
            });
    }

    private updateBreadcrumb(url: string): void {
        const cleanUrl = this.cleanUrl(url);
        const parts = cleanUrl.split('/');
        this.breadcrumb = this.generateBreadcrumb(parts, this.tabs);
    }

    private cleanUrl(url: string): string {
        return url
            .replace('/admin/', '')
            .replace(/\/\d+/g, '')
            .replace(/\?.*$/g, '');
    }

    private generateBreadcrumb(parts: string[], menuItems: MenuItem[]): string[] {
        const breadcrumb: string[] = [];
        let currentItems = menuItems;

        for (const part of parts) {
            const matchingItem = currentItems.find(item => {
                const itemPath = item.path.replace(/^:.*\//g, '').replace(/\/:.*$/g, '');
                return itemPath === part;
            });

            if (matchingItem?.name) {
                breadcrumb.push(matchingItem.name);
                currentItems = matchingItem.children || [];
            }
        }

        return breadcrumb;
    }

    private buildMenu(config: any): MenuItem[] {
        return config.children
            .filter((i: MenuItem) => this.isMenuItemVisible(i))
            .map((i: MenuItem) => this.processMenuItem(i));
    }

    private isMenuItemVisible(menuItem: MenuItem): boolean {
        return this.checkMenuVisiblePermission(menuItem) &&
            this.permissionService.filterMenu(menuItem) &&
            this.checkUserPermissions(menuItem);
    }

    private checkMenuVisiblePermission(node: MenuItem): boolean {
        return !!node.name && !node.hidden;
    }

    private checkUserPermissions(menuItem: MenuItem): boolean {
        const {role, type} = menuItem.data || {};
        return (!role || role.includes(this.user!.type)) &&
            (!type || type.includes(this.user!.school_type));
    }

    private processMenuItem(menuItem: MenuItem): MenuItem {
        if (menuItem.children?.length) {
            return this.buildSubMenus(menuItem, [], []);
        } else {
            menuItem.tree = [];
            menuItem.url = `/admin/${menuItem.path}`;
            return menuItem;
        }
    }

    private buildSubMenus(item: MenuItem, path: string[], breadcrumb: string[]): MenuItem {
        const newBreadcrumb = [...breadcrumb, item.name];
        const newPath = [...path, item.path];

        if (item.children?.length) {
            item.tree = item.children
                .filter(this.isMenuItemVisible.bind(this))
                .map(child => this.buildSubMenus(child, newPath, newBreadcrumb));

            if (item.tree.length === 0) {
                newPath.push('index');
            }
        } else {
            item.tree = [];
        }

        item.breadcrumb = newBreadcrumb;
        item.url = `/admin/${newPath.join('/')}`;
        return item;
    }
}
