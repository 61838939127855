import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {BaseService} from './base.service';
import {Router} from '@angular/router';
import {VideoShowResourceCategory} from '../model/VideoShowResourceCategory';
import {from, Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {BookBarSetting} from '../model/BookBarSetting';

@Injectable({
    providedIn: 'root'
})

export class BookBarSettingService extends BaseService {
    constructor(protected override http: HttpClient, protected override router: Router) {
        super(http, router);
    }

    add(setting: BookBarSetting): Observable<number> {
        return from(super.post('/book-bar/setting', setting))
            .pipe(map(id => id as number));
    }

    edit(setting: BookBarSetting): Observable<void> {
        return from(super.put('/book-bar/setting', setting));
    }

    queryBySchoolId(school_id: number): Observable<BookBarSetting> {
        return from(super.get(`/book-bar/setting/school/${school_id}`))
            .pipe(
                map(result => result as BookBarSetting)
            );
    }

}
