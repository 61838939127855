<div class="add-class">
    <div class="table-operations">
        <a nz-button nzType="text" (click)="goBack()"><span nz-icon nzType="arrow-left" nzTheme="outline"></span>返回</a>
        <nz-divider nzType="vertical"></nz-divider>
        <span *ngIf="!videoShowResourceId">新建</span>
        <span *ngIf="videoShowResourceId">修改 {{resourceName}}</span>
    </div>
    <form nz-form [formGroup]="validateForm" (ngSubmit)="submitForm()">
        <nz-form-item>
            <nz-form-label [nzSpan]="5" nzRequired>标题</nz-form-label>
            <nz-form-control [nzSpan]="16" nzHasFeedback nzErrorTip="请输入标题！">
                <input nz-input formControlName="name" placeholder="请输入">
            </nz-form-control>
        </nz-form-item>
        <nz-form-item>
            <nz-form-label [nzSpan]="5" nzRequired>文本</nz-form-label>
            <nz-form-control [nzSpan]="16" nzHasFeedback nzErrorTip="请输入文本！">
                <textarea formControlName="content" nz-input rows="15" placeholder="请输入"></textarea>
            </nz-form-control>
        </nz-form-item>
        <nz-form-item>
            <nz-form-control [nzOffset]="5" [nzSpan]="16">
                <button style="float: right" nz-button nzType="primary" [disabled]="!validateForm.valid">保存</button>
            </nz-form-control>
        </nz-form-item>
    </form>
</div>
