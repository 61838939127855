import {Component, EventEmitter, OnDestroy, Output} from '@angular/core';
import {Subscription} from 'rxjs';
import {UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import {UserService} from '../../../../../../../services/user.service';
import {BookBarSettingService} from '../../../../../../../services/book-bar-setting.service';
import {NzMessageService} from 'ng-zorro-antd/message';
import {BookBarSetting} from '../../../../../../../model/BookBarSetting';

@Component({
    selector: 'app-book-bar-setting-form',
    templateUrl: './book-bar-setting-form.component.html',
    styleUrls: ['./book-bar-setting-form.component.scss']
})
export class BookBarSettingFormComponent implements OnDestroy {

    @Output() settingChangedEvent = new EventEmitter<BookBarSetting>;

    private subscriptions: Subscription = new Subscription();

    validateForm!: UntypedFormGroup;

    constructor(private fb: UntypedFormBuilder,
                private userService: UserService,
                private bookBarSettingService: BookBarSettingService,
                private msg: NzMessageService) {
        this.initFormGroup();
        this.initFormGroupValue();
    }

    initFormGroup(): void {
        this.validateForm = this.fb.group({
            id: [],
            school_id: [this.userService.getUserInfo().school_id],
            name: ['', [Validators.required]],
            phone: ['', [Validators.required, Validators.pattern('(1[3-9]\\d{9}|(?:0\\d{2,3}-)?\\d{7,8})')]],
            address: ['', [Validators.required]],
            highlight: ['', [Validators.required]],
            introduction: ['', [Validators.required]]
        });
        this.subscriptions.add(
            this.validateForm.valueChanges.subscribe(
                value => this.settingChangedEvent.emit(value)
            )
        );
    }

    initFormGroupValue(): void {
        const login_user = this.userService.getUserInfo();
        this.subscriptions.add(
            this.bookBarSettingService.queryBySchoolId(login_user.school_id)
                .subscribe(setting => {
                    this.validateForm.controls['id'].setValue(setting.id);
                    this.validateForm.controls['name'].setValue(setting.name);
                    this.validateForm.controls['phone'].setValue(setting.phone);
                    this.validateForm.controls['address'].setValue(setting.address);
                    this.validateForm.controls['highlight'].setValue(setting.highlight);
                    this.validateForm.controls['introduction'].setValue(setting.introduction);
                })
        );
    }

    submitForm(): void {
        console.log(this.validateForm.value);
        if (this.validateForm.controls['id'].value) {
            this.subscriptions.add(
                this.bookBarSettingService.edit(this.validateForm.value).subscribe(() => this.msg.success('保存成功'))
            );
        } else {
            this.subscriptions.add(
                this.bookBarSettingService.add(this.validateForm.value)
                    .subscribe(id => {
                        this.validateForm.controls['id'].setValue(id);
                        this.msg.success('保存成功');
                    })
            );
        }
    }

    ngOnDestroy(): void {
        this.subscriptions.unsubscribe();
    }
}
