<div class="table-operations">
    <button
      *ngIf="user.type === UserType.SUPER_ADMIN"
      nz-button nzType="primary"
      [nzSize]="'large'"
      [routerLink]="'/admin/schools/add'">
        <i nz-icon nzType="plus" nzTheme="outline"></i>添加学校</button>
</div>
<nz-table class="data-table" #dataTable [nzData]="displayData"
          [nzLoading]="loading"
          [nzFrontPagination]="true"
          [nzPageSize]="10"
          [(nzPageIndex)]="currentPageIndex"
          [nzTotal]="totalNum">
    <thead>
<!--    (nzPageIndexChange)="loadData($event)"-->
    <tr>
        <th>id</th>
        <th nzCustomFilter [ngClass]="{'searched': searchValue }">学校名称
            <i
                class="ant-table-filter-icon"
                nz-icon
                nz-dropdown
                #dropdown="nzDropdown"
                nzType="search"
                [nzDropdownMenu]="schoolNameSearchMenu"
                [class.ant-table-filter-open]="dropdown.nzVisible"
                nzTrigger="click"
                nzPlacement="bottomRight"
                [nzClickHide]="true"
                nzTableFilter
            ></i>
        </th>
        <th>管理员名称</th>
        <th>管理员电话</th>
        <th>查看绘本</th>
        <th nzShowFilter
            [nzFilters]="listOfType"
            (nzFilterChange)="filterType($event)">学校类型</th>
        <th>学校状态</th>
        <th>小程序状态</th>
        <th style="text-align: center">操作</th>
    </tr>
    </thead>
    <tbody>
    <tr *ngFor="let data of dataTable.data">
        <td>{{data.id}}</td>
        <td>
            <i *ngIf="data.customized" [nzTwotoneColor]="'#52c41a'" nz-icon nzType="skin" nzTheme="twotone"></i>
            {{data.school_name}}</td>
        <td>{{data.name}}</td>
        <td>{{data.phone}}</td>
        <td>{{data.config && data.config[0] && data.config[0].checked ? '是' : '否'}}</td>
        <td>

          <ng-container *ngFor="let schoolType of schoolTypes">
            <ng-container *ngIf="data.type == schoolType.type ">
            <div *ngIf="user.type === UserType.SUPER_ADMIN; then adminTypeTpl else purchaseTypeTpl"></div>
            <ng-template #adminTypeTpl>
              <a nz-popconfirm nzPopconfirmTitle="确定切换学校类型？"
                                      (nzOnConfirm)="changeType(data.id, (schoolType.type + 1) % schoolTypes.length == 0 ? 1 : (schoolType.type + 1) % schoolTypes.length)">
              <nz-tag style="cursor: pointer" [nzColor]="schoolType.color">{{schoolType.label}}</nz-tag>
            </a></ng-template>
            <ng-template #purchaseTypeTpl>
              <nz-tag style="cursor: pointer" [nzColor]="schoolType.color">{{schoolType.label}}</nz-tag></ng-template>
            </ng-container>
          </ng-container>
        </td>

<!--        <td *ngIf="data.type == 0" ><a nz-popconfirm nzPopconfirmTitle="确定切换学校类型？"-->
<!--                                       (nzOnConfirm)="changeType(data.id, 1)"><nz-tag style="cursor: pointer" [nzColor]="'#f50'">未设置</nz-tag></a></td>-->
<!--        <td *ngIf="data.type == 1" ><a nz-popconfirm nzPopconfirmTitle="确定切换学校类型？"-->
<!--                                       (nzOnConfirm)="changeType(data.id, 2)"><nz-tag style="cursor: pointer" [nzColor]="'#2db7f5'">直营校</nz-tag></a></td>-->
<!--        <td *ngIf="data.type == 2" ><a nz-popconfirm nzPopconfirmTitle="确定切换学校类型？"-->
<!--                                       (nzOnConfirm)="changeType(data.id, 3)"><nz-tag style="cursor: pointer" [nzColor]="'#87d068'">加盟校</nz-tag></a></td>-->
<!--        <td *ngIf="data.type == 3" ><a nz-popconfirm nzPopconfirmTitle="确定切换学校类型？"-->
<!--                                       (nzOnConfirm)="changeType(data.id, 1)"><nz-tag style="cursor: pointer" [nzColor]="'#68d0bb'">按量付费校</nz-tag></a></td>-->
        <td>
            <span style="color: red" *ngIf="data.status === 1">停用</span>
            <span style="color: green" *ngIf="data.status === 0">正常</span>
        </td>
      <td>

        <div *ngIf="user.type === UserType.SUPER_ADMIN; then adminWxTpl else purchaseWxTpl"></div>
        <ng-template #adminWxTpl>
          <div [ngStyle]="{cursor: 'pointer'}" (click)="toggleMicroAppStatus(data.id, data.settings.microAppStatus)">
            <ng-container *ngTemplateOutlet="purchaseWxTpl">
            </ng-container>
          </div>

        </ng-template>
        <ng-template #purchaseWxTpl>
          <nz-tag nzColor="success" *ngIf="data.settings.microAppStatus===undefined || data.settings.microAppStatus === 1">
            <span nz-icon nzType="check-circle"></span>
            <span>已启用</span>
          </nz-tag>

          <nz-tag nzColor="error" *ngIf="data.settings.microAppStatus === 0">
            <span nz-icon nzType="close-circle"></span>
            <span>已禁用</span>
          </nz-tag>
        </ng-template>


      </td>
        <td style="width:22rem" [ngStyle]="{'width': user.type === UserType.SUPER_ADMIN ? '22rem' : '8rem'}">
            <div class="operations">
                <a nz-popconfirm nzPopconfirmTitle="确定停用学校？"
                   *ngIf="user.type === UserType.SUPER_ADMIN && data.status === 0"
                   (nzOnConfirm)="disableSchool(data.id)" >
                    停用
                </a>
                <a nz-popconfirm nzPopconfirmTitle="确定启用学校？"
                   *ngIf="user.type === UserType.SUPER_ADMIN &&  data.status === 1"
                   (nzOnConfirm)=" enableSchool(data.id)" >
                    启用
                </a>
                <a *ngIf="user.type === UserType.SUPER_ADMIN" (click)=" editSchool(data.id)">编辑</a>
                <a *ngIf="data.type != 3"  (click)=" addSchoolOrders(data.id)">手动添加</a>
                <a *ngIf="user.type === UserType.SUPER_ADMIN" (click)=" splitSchool(data.id)">迁校</a>

            </div>

        </td>
    </tr>
    </tbody>
</nz-table>
<nz-dropdown-menu #schoolNameSearchMenu="nzDropdownMenu">
    <div class="ant-table-filter-dropdown">
    <div class="search-box">
        <input type="text" nz-input placeholder="Search name" [(ngModel)]="searchValue" />
        <button nz-button nzSize="small" nzType="primary" (click)="search()" class="search-button">
            Search
        </button>
        <button nz-button nzSize="small" (click)="reset()">Reset</button>
    </div>
    </div>
</nz-dropdown-menu>


<nz-modal
        [nzWidth]="720"
        [nzKeyboard]="false"
        [nzMaskClosable]="false"
        [nzClosable]="false"
        [(nzVisible)]="isSplitSchoolVisible"
        [nzTitle]="modalTitle"
        [nzContent]="modalContent"
        [nzFooter]="modalFooter"
        (nzOnCancel)="isSplitSchoolVisible = false"
>
    <ng-template #modalTitle>
        转移班级
    </ng-template>

    <ng-template #modalContent>
        <div>
            <nz-spin [nzSpinning]="isLoadingSplitSchoolClasses">
<!--                <div style="margin-bottom: 20px;">-->
<!--                    <label nz-checkbox [(ngModel)]="splitSchoolClassWithTeacher">包含班级教师</label>-->
<!--                </div>-->
                <nz-transfer
                        [nzDataSource]="splitSchoolClassList"
                        [nzListStyle]="{ 'width.px': 300, 'height.px': 300 }"
                        [nzRender]="render"
                        (nzSelectChange)="selectSplitSchoolClass($event)"
                        (nzChange)="changeSplitSchoolClass($event)"
                >
                    <ng-template #render let-item>  {{ item.class_name }} ({{item.teacher}})</ng-template>
                </nz-transfer>
                <div nz-row style="margin-top: 20px;">
                    <div nz-col style="width: calc(100% - 200px);    display: inline-block;">
                        <nz-select
                                style="width: 100%;"
                                [(ngModel)]="selectedSplitTargetSchool"
                                (ngModelChange)="changeSplitSchool($event)"
                                nzPlaceHolder="Type to search"
                                nzAllowClear
                                nzShowSearch
                                [nzServerSearch]="false"
                                (nzOnSearch)="onSearchSplitTargetSchool($event)"
                        >
                            <nz-option *ngFor="let o of optionSchoolList" [nzValue]="o.id" [nzLabel]="o.school_name"></nz-option>
                            <nz-option *ngIf="displaySearchSchoolTips" nzDisabled nzCustomContent>
                                最少输入两个汉字
                            </nz-option>
                        </nz-select>
                    </div>
                    <div nz-col style="width: 200px; display: inline-block;">
                        <button style=" margin-left:20px;" nz-button nzType="primary"
                                [nzLoading]="isSplittingSchool"
                                (click)="doSplitSchool()">转移</button>
                    </div>
                    <!--            style="margin-top: 20px;" *ngIf="displaySplitSchoolClasses"-->

                </div>

            </nz-spin>
        </div>

    </ng-template>

    <ng-template #modalFooter>
        <button nz-button [disabled]="isSplittingSchool"
                (click)="closeSplitSchoolModal()">关闭</button>
    </ng-template>
</nz-modal>
