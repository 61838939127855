import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {BaseService} from './base.service';
import {Router} from '@angular/router';
import {VideoShowResourceCategory} from '../model/VideoShowResourceCategory';
import {from, Observable} from 'rxjs';
import {map} from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})

export class VideoShowResourceCategoryService extends BaseService {
    constructor(protected override http: HttpClient, protected override router: Router) {
        super(http, router);
    }

    add(category: VideoShowResourceCategory): Observable<number> {
        return from(super.post('/video-show-resource/categories', category))
            .pipe(map(id => id as number));
    }

    delete(id: number): Observable<void> {
        return from(super.remove(`/video-show-resource/categories/${id}`));
    }

    edit(category: VideoShowResourceCategory): Observable<void> {
        return from(super.put('/video-show-resource/categories', category));
    }

    query(): Observable<VideoShowResourceCategory[]> {
        return from(super.get('/video-show-resource/categories'))
            .pipe(
                map(result => result as VideoShowResourceCategory[])
            );
    }

}
