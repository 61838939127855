import {Component, EventEmitter, OnDestroy, OnInit, Output} from '@angular/core';
import {SelectionModel} from '@angular/cdk/collections';
import {FlatTreeControl} from '@angular/cdk/tree';
import {NzTreeFlatDataSource, NzTreeFlattener} from 'ng-zorro-antd/tree-view';
import {NodeStatus} from '../../../../../../../model/VideoShowResourceCategoryStatus';
import {VideoShowResourceCategory} from '../../../../../../../model/VideoShowResourceCategory';
import {VideoShowResourceCategoryService} from '../../../../../../../services/video-show-resource-category.service';
import {VideoShowResourceService} from '../../../../../../../services/video-show-resource.service';
import {NzMessageService} from 'ng-zorro-antd/message';
import {filter, switchMap, tap} from 'rxjs/operators';
import {UserService} from '../../../../../../../services/user.service';
import {SCHOOL_TYPE, UserType} from '../../../../../../../model/Const';
import {Subscription} from 'rxjs';
// @ts-ignore
import { v4 as uuidv4 } from 'uuid';


// the flattened structure used by the nz-tree-view on the html page
interface FlatNode {
    expandable: boolean;
    name: string;
    key: string;
    level: number;
    status: NodeStatus;
    readonly: boolean;
}

@Component({
    selector: 'app-video-show-resource-category',
    templateUrl: './video-show-resource-category.component.html',
    styleUrls: ['./video-show-resource-category.component.scss']
})
export class VideoShowResourceCategoryComponent implements OnInit, OnDestroy {

    // emit the event while clicking the second level category and notify the resource table to reload data
    @Output() categoryClickEvent = new EventEmitter<{ categoryId: number, readonly: boolean }>();

    private subscriptions: Subscription = new Subscription();
    loginUserSchoolId: number;
    hierarchyCategoryArr: VideoShowResourceCategory[] = [];
    flatNodeMap = new Map<FlatNode, VideoShowResourceCategory>();
    hierarchyCategoryMap = new Map<VideoShowResourceCategory, FlatNode>();
    selectListSelection = new SelectionModel<FlatNode>(true);

    treeControl = new FlatTreeControl<FlatNode>(
        node => node.level,
        node => node.expandable
    );
    treeFlattener = new NzTreeFlattener(
        (node: VideoShowResourceCategory, level: number): FlatNode => {
            let flatNode;
            const existingNode = this.hierarchyCategoryMap.get(node);
            if (existingNode && existingNode.key === node.key) {
                flatNode = existingNode;
                flatNode.name = node.name;
                flatNode.status = node.status;
            } else {
                flatNode = {
                    expandable: level === 0, // 是否可以展开 & 是否可以添加子节点
                    name: node.name,
                    level,
                    key: node.key,
                    status: node.status,
                    readonly: this.loginUserSchoolId !== node.school_id
                };
            }
            this.flatNodeMap.set(flatNode, node);
            this.hierarchyCategoryMap.set(node, flatNode);
            return flatNode;
        },
        node => node.level,
        node => node.expandable,
        node => node.children // will recursively flatten his children.
    );
    dataSource = new NzTreeFlatDataSource(this.treeControl, this.treeFlattener);

    isRootLevel = (_: number, node: FlatNode): boolean => node.level === 0;
    isNoneRootLevel = (_: number, node: FlatNode): boolean => node.level !== 0;
    isAdding = (_: number, node: FlatNode): boolean => node.status === NodeStatus.Adding;
    isEditing = (_: number, node: FlatNode): boolean => node.status === NodeStatus.Editing;
    // If trackBy changes, the corresponding node in the treeView will be updated and re-rendered.
    trackBy = (_: number, node: FlatNode): string => `${node.key}-${node.status}`;

    constructor(private videoShowResourceCategoryService: VideoShowResourceCategoryService,
                private videoShowResourceService: VideoShowResourceService,
                private userService: UserService,
                private msg: NzMessageService) {
        const userInfo = this.userService.getUserInfo();
        this.loginUserSchoolId = userInfo.school_id;
        if (userInfo.type === UserType.TEACH_RESEARCHER) {
            this.loginUserSchoolId = SCHOOL_TYPE.NONE;
        }
    }

    ngOnInit(): void {
        this.subscriptions.add(
            this.videoShowResourceCategoryService.query()
                .subscribe(categories => {
                        this.hierarchyCategoryArr = categories;
                        this.dataSource.setData(this.hierarchyCategoryArr);
                        this.treeControl.expandAll();
                    },
                    () => this.msg.error('查询视频秀失败，请联系管理员'))
        );
    }

    addRootCategory() {

        this.hierarchyCategoryArr.push({
            id: Number.NaN,
            school_id: this.loginUserSchoolId,
            name: '',
            key: uuidv4(),
            status: NodeStatus.Adding,
        });
        this.dataSource.setData(this.hierarchyCategoryArr);
    }

    addChildCategory(node: FlatNode) {
        const hierarchyCategory = this.flatNodeMap.get(node);
        if (!hierarchyCategory) {
            return;
        }

        hierarchyCategory.children = hierarchyCategory.children || [];
        hierarchyCategory.children.push({
            id: Number.NaN,
            school_id: this.loginUserSchoolId,
            name: '',
            key: uuidv4(),
            status: NodeStatus.Adding,
            parent_id: hierarchyCategory.id
        });
        this.dataSource.setData(this.hierarchyCategoryArr);
        this.treeControl.expand(node); // expand the parent node to ensure the new adding category could be seen
    }

    cancelAddingCategory(node: FlatNode): void {
        this.removeFromTheCategoryHierarchy(node, this.hierarchyCategoryArr);
        this.dataSource.setData(this.hierarchyCategoryArr);
    }

    saveNewCategory(node: FlatNode, value: string): void {
        if (!value) {
            this.msg.error('请输入视频秀名称');
            return;
        }

        const hierarchyCategory = this.flatNodeMap.get(node);
        if (!hierarchyCategory) {
            return;

        }

        hierarchyCategory.name = value;
        this.subscriptions.add(
            this.videoShowResourceCategoryService.add(hierarchyCategory).subscribe(id => {
                hierarchyCategory.id = id;
                hierarchyCategory.status = NodeStatus.Display;
                this.dataSource.setData(this.hierarchyCategoryArr);
            }, (error) => {
                console.log(error);
                this.msg.error(error.message || '创建视频秀失败，请联系管理员');
            })
        );
    }

    editCategory(node: FlatNode): void {
        this.flatNodeMap.get(node)!.status = NodeStatus.Editing;
        this.dataSource.setData(this.hierarchyCategoryArr);
    }

    cancelEditingCategory(node: FlatNode): void {
        const hierarchyCategory = this.flatNodeMap.get(node);
        if (hierarchyCategory) {
            hierarchyCategory.status = NodeStatus.Display;
            this.dataSource.setData(this.hierarchyCategoryArr);
        }
    }

    saveExistingCategory(node: FlatNode, value: string): void {
        if (!value) {
            this.msg.error('请输入视频秀名称');
            return;
        }

        const hierarchyCategory = this.flatNodeMap.get(node);
        if (!hierarchyCategory) {
            return;
        }

        hierarchyCategory.name = value;
        this.subscriptions.add(
            this.videoShowResourceCategoryService.edit(hierarchyCategory).subscribe(() => {
                hierarchyCategory.status = NodeStatus.Display;
                this.dataSource.setData(this.hierarchyCategoryArr);
                this.treeControl.expand(node);
            }, () => this.msg.error('编辑视频秀失败，请联系管理员'))
        );
    }

    deleteCategory(node: FlatNode): void {
        const hierarchyCategory = this.flatNodeMap.get(node);
        if (!hierarchyCategory) {
            return;
        }

        if (hierarchyCategory.children?.length) {
            this.msg.error('该分类下有下级分类，请将其删除后再删除本分类');
            return;
        }

        this.subscriptions.add(
            this.videoShowResourceService.queryByPage(hierarchyCategory.id, 1)
                .pipe(
                    tap(res => {
                            if (res.total > 0) {
                                this.msg.error('该分类下有内容，请将内容删除后再删除本分类');
                            }
                        }
                    ),
                    filter(res => res.total === 0),
                    switchMap(() => this.videoShowResourceCategoryService.delete(hierarchyCategory.id))
                ).subscribe(() => {
                this.removeFromTheCategoryHierarchy(node, this.hierarchyCategoryArr);
                this.dataSource.setData(this.hierarchyCategoryArr);
            }, () => this.msg.error('删除视频秀失败，请联系管理员'))
        );
    }

    // trigger reloading the resource table on the right while click the category node
    loadVideoShowResourceTable(node: FlatNode): void {
        this.selectListSelection.setSelection(node);
        const categoryId = this.flatNodeMap.get(node)?.id;
        if (categoryId) {
            this.categoryClickEvent.emit({categoryId, readonly: node.readonly});
        }
    }

    // remove the treeNode from the hierarchy array whose key is equals to the flatNode's key.
    // Return true when the node found and removed.
    // Return false if the node is not found, or could not be removed due to having children.
    private removeFromTheCategoryHierarchy(node: FlatNode, arr: VideoShowResourceCategory[]): void {
        for (let i = 0; i < arr.length; i++) {
            const hierarchyCategory = arr[i];
            if (node.key === hierarchyCategory.key) {
                // remove the corresponding entry from the two maps
                this.hierarchyCategoryMap.forEach((v, k) => {
                    if (k.key === hierarchyCategory.key) {
                        this.hierarchyCategoryMap.delete(k);
                    }
                });
                this.flatNodeMap.forEach((v, k) => {
                    if (k.key === hierarchyCategory.key) {
                        this.flatNodeMap.delete(k);
                    }
                });

                // remove the node from the array
                arr.splice(i, 1);
                return;
            }

            // try to match the removing node from current node children recursively
            if (hierarchyCategory.children) {
                this.removeFromTheCategoryHierarchy(node, hierarchyCategory.children!);
            }
        }
    }

    ngOnDestroy(): void {
        this.subscriptions.unsubscribe();
    }
}
