<div nz-row>
    <div nz-col nzOffset="2" nzSpan="10">
        <h3>分享页预览</h3>
    </div>
    <div nz-col nzSpan="10">
        <h3>书吧信息配置</h3>
    </div>
</div>

<div nz-row>
    <div nz-col nzOffset="2" nzSpan="10">
        <app-book-bar-setting-preview [setting]="setting"></app-book-bar-setting-preview>
    </div>
    <div nz-col nzSpan="10">
        <app-book-bar-setting-form (settingChangedEvent)="settingChanged($event)"></app-book-bar-setting-form>
    </div>
</div>
