<div style="margin-bottom: 30px">
    内容列表
    <nz-divider nzType="vertical"></nz-divider>
    <a nz-button
       *ngIf="!readonly && this.category_id"
       nzType="link"
       (click)="this.openResourceFormEvent.emit()">
        <span nz-icon nzType="plus" nzTheme="outline"></span>新建
    </a>
</div>

<nz-table #dataTable [nzData]="searchResult.rows"
          [nzFrontPagination]="false"
          [nzPageSize]="pageSize"
          [(nzPageIndex)]="searchResult.currentPage"
          (nzPageIndexChange)="loadData($event)"
          [nzTotal]="searchResult.total">
    <thead>
    <tr>
        <th>序号</th>
        <th>名称</th>
        <th>创建日期</th>
        <th>操作</th>
    </tr>
    </thead>
    <tbody>
    <tr *ngFor="let data of dataTable.data">
        <td>{{ data.sequence }}</td>
        <td>{{ data.name }}</td>
        <td>{{ data.created_date }}</td>
        <td>
            <ng-container *ngIf="!readonly">
                <a nz-button [nzSize]="'small'" nzType="link" (click)="this.openResourceFormEvent.emit(data.id)">编辑</a>
                <nz-divider nzType="vertical"></nz-divider>
                <a nz-popconfirm nzPopconfirmTitle="删除后无法恢复，确认删除吗？" (nzOnConfirm)="delete(data.id)">删除</a>
            </ng-container>
        </td>
    </tr>
    </tbody>
</nz-table>
