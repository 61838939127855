import {Component, ViewChild} from '@angular/core';
import {VideoShowResourceCategoryComponent} from './video-show-resource-category/video-show-resource-category.component';

@Component({
    selector: 'app-video-show',
    templateUrl: './video-show.component.html',
    styleUrls: ['./video-show.component.scss']
})

export class VideoShowComponent {

    @ViewChild(VideoShowResourceCategoryComponent) videoShowTreeMenuComponent!: VideoShowResourceCategoryComponent;

    videoShowCategoryId?: number;
    videoShowCategoryReadonly?: boolean;
    videoShowResourceId?: number;

    displayVideoShowResourceTable = true;


    addVideoShowCategory(): void {
        this.videoShowTreeMenuComponent.addRootCategory();
    }

    categoryClicked({categoryId, readonly}: { categoryId: number; readonly: boolean }): void {
        this.videoShowCategoryId = categoryId;
        this.videoShowCategoryReadonly = readonly;
        this.displayVideoShowResourceTable = true;
    }

    openVideoShowResourceForm(resourceId?: number) {
        this.videoShowResourceId = resourceId;
        this.displayVideoShowResourceTable = false;
    }

}
