<div class="setting-preview">
    <div nz-row>
        <div nz-col nzOffset="3" nzSpan="18" style="margin-top: 50px;">
            <h2 style="color: #F3CB4F">{{ setting?.name }}</h2>
            <h4 style="color: white">立即获得试听机会</h4>
            <p class="setting-phone-address" style="min-height: 30px">
                <span *ngIf="setting?.phone.length>0">联系电话: {{ setting.phone }}</span>
                <br>
                <span *ngIf="setting?.address.length>0">地址: {{ setting.address }}</span>
            </p>
        </div>
    </div>

    <div nz-row>
        <div nz-col nzOffset="4" nzSpan="19">
            <p style="color: #AFAFAF;margin-top: 50px">输入手机号</p>
        </div>
    </div>

    <div nz-row>
        <div nz-col nzOffset="2" nzSpan="19" class="request-button">
            <p style="margin-top: 15px; color: white">立即领取体验卡</p>
        </div>
    </div>

    <div nz-row class="setting-highlight">
        <div nz-col nzOffset="2" nzSpan="20">
            <h3>{{ setting?.name }}简介</h3>
            <p>{{ setting?.highlight }}</p>
        </div>
    </div>

    <div nz-row class="setting-introduction">
        <div nz-col nzOffset="2" nzSpan="20">
            <p>
                {{ setting?.introduction }}
            </p>
        </div>
    </div>

</div>
