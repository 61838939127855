import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {BaseService} from './base.service';
import {Router} from '@angular/router';
import {VideoShowResourceCategory} from '../model/VideoShowResourceCategory';
import {from, Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {VideoShowResource} from '../model/VideoShowResource';
import {PaginationSearchResult} from '../model/PaginationSearchResult';

@Injectable({
    providedIn: 'root'
})

export class VideoShowResourceService extends BaseService {
    constructor(protected override http: HttpClient, protected override router: Router) {
        super(http, router);
    }

    add(category: VideoShowResourceCategory): Observable<number> {
        return from(super.post('/video-show-resources', category))
            .pipe(map(id => id as number));
    }

    delete(id: number): Observable<void> {
        return from(super.remove(`/video-show-resources/${id}`));
    }

    edit(category: VideoShowResourceCategory): Observable<void> {
        return from(super.put('/video-show-resources', category));
    }

    queryByPage(category_id: number, page: number): Observable<PaginationSearchResult<VideoShowResource>> {
        const searchParams = new URLSearchParams();
        searchParams.append('category_id', `${category_id}`);
        searchParams.append('page', `${page}`);
        return from(super.get(`/video-show-resources?${searchParams.toString()}`))
            .pipe(
                map(result => result as PaginationSearchResult<VideoShowResource>)
            );
    }

    queryById(id: number): Observable<VideoShowResource> {
        return from(super.get(`/video-show-resources/${id}`))
            .pipe(
                map(result => result as VideoShowResource)
            );
    }

}
