<nz-layout class='admin-layout'>
    <nz-layout style="position: relative;">
        <app-admin-header></app-admin-header>

        <nz-layout>
            <nz-sider nzCollapsible
                      [nzBreakpoint]="'sm'">
                <ul nz-menu
                    [nzTheme]="'dark'"
                    [nzMode]="'inline'">
                    <ng-container *ngFor="let tab of tabs">
                        <!--Two levels menu, root level and its children-->
                        <li *ngIf="tab.tree.length" nz-submenu [nzTitle]="tab.name" [nzIcon]="tab.icon"
                            [nzOpen]="tab.expanded">
                            <ul>
                                <li *ngFor="let sub of tab.tree" nz-menu-item nzMatchRouter>
                                    <a [routerLink]="sub.url">
                                        <i *ngIf="sub.icon" class="{{sub.icon}}"></i> {{ sub.name }}
                                    </a>
                                </li>
                            </ul>
                        </li>

                        <!--only one level menu-->
                        <li *ngIf="!tab.tree.length" nz-menu-item nzMatchRouter>
                            <a [routerLink]="tab.url">
                                <i *ngIf="tab.icon" nz-icon nzType="{{tab.icon}}" nzTheme="outline"></i>
                                <span class="nav-text b">{{ tab.name }}</span>
                            </a>
                        </li>
                    </ng-container>
                </ul>
            </nz-sider>


            <nz-content class="admin-main">
                <nz-breadcrumb style="margin:16px 0;">
                    <nz-breadcrumb-item *ngFor="let bc of breadcrumb">{{ bc }}</nz-breadcrumb-item>
                </nz-breadcrumb>
                <div class="admin-body" style="padding: 24px;background: #fff;margin-bottom: 24px;">
                    <router-outlet></router-outlet>
                </div>
            </nz-content>

        </nz-layout>

    </nz-layout>
</nz-layout>
<ng-template #trigger>
    <i class="anticon anticon-up"></i>
</ng-template>
