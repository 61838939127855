import {Component, EventEmitter, Input, OnChanges, OnDestroy, Output, SimpleChanges} from '@angular/core';
import {VideoShowResourceService} from '../../../../../../../services/video-show-resource.service';
import {PaginationSearchResult} from '../../../../../../../model/PaginationSearchResult';
import {VideoShowResource} from '../../../../../../../model/VideoShowResource';
import {tap} from 'rxjs/operators';
import {DatePipe} from '@angular/common';
import {NzMessageService} from 'ng-zorro-antd/message';
import {Subscription} from 'rxjs';

@Component({
    selector: 'app-video-show-resource-table',
    templateUrl: './video-show-resource-table.component.html',
    styleUrls: ['./video-show-resource-table.component.scss'],
    providers: [DatePipe]
})
export class VideoShowResourceTableComponent implements OnChanges, OnDestroy {

    @Input() category_id!: number;
    @Input() readonly !: boolean;

    @Output() openResourceFormEvent = new EventEmitter<number | undefined>();

    private subscriptions: Subscription = new Subscription();
    pageSize = 10;

    searchResult: PaginationSearchResult<VideoShowResource> = {
        rows: [],
        total: 0,
        displayCount: 0,
        pageNum: 1,
        start: 0,
        end: 0,
        currentPage: 1
    };

    constructor(private videoShowResourceService: VideoShowResourceService,
                private datePipe: DatePipe,
                private msg: NzMessageService) {
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (!changes['category_id'] || !changes['category_id'].currentValue) {
            return;
        }
        this.loadData(1);
    }

    loadData(page: number): void {
        this.subscriptions.add(
            this.videoShowResourceService.queryByPage(this.category_id, page)
                .pipe(
                    tap(result => {
                        result.rows?.forEach((item, index) => {
                            item.sequence = (page - 1) * this.pageSize + index + 1;
                        });
                    }),
                    tap(result => {
                        result.rows?.forEach(item => {
                            item.created_date = this.datePipe.transform(item.created_date, 'yyyy-MM-dd') || '';
                        });
                    })
                )
                .subscribe(result => {
                    this.searchResult = result;
                }, () => this.msg.error('加载视频秀资源失败，请联系管理员'))
        );
    }

    delete(id: number): void {
        this.subscriptions.add(
            this.videoShowResourceService.delete(id).subscribe(
                () => this.loadData(1),
                () => this.msg.error('删除视频秀资源失败，请联系管理员'))
        );
    }

    ngOnDestroy(): void {
        this.subscriptions.unsubscribe();
    }

}
