<nz-table #dataTable [nzData]="searchResult.rows"
          [nzFrontPagination]="false"
          [nzPageSize]="pageSize"
          [(nzPageIndex)]="searchResult.currentPage"
          (nzPageIndexChange)="onPageIndexChange($event)"
          [nzTotal]="searchResult.total"
          (nzQueryParams)="onQueryParamsChange($event)">
    <thead>
    <tr>
        <th>手机号</th>
        <th>日期</th>
        <th nzColumnKey="tracked" [nzFilters]="filterTracked" [nzFilterMultiple]="false" [nzFilterFn]="true">状态</th>
        <th>操作</th>
    </tr>
    </thead>
    <tbody>
    <tr *ngFor="let data of dataTable.data">
        <td>{{ data.request_phone }}</td>
        <td>{{ data.created_date | date: 'yyyy-MM-dd' }}</td>
        <td *ngIf="data.tracked ===1"><a nz-button nzType="link" disabled>已跟进</a></td>
        <td *ngIf="data.tracked ===0"><a nz-button nzType="link" nzDanger>未跟进</a></td>
        <td><a nz-button nzType="primary" [disabled]="data.tracked ===1" (click)="markAsTracked(data.id)">标记为已跟进</a></td>
    </tr>
    </tbody>
</nz-table>
