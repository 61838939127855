<div nz-row>
    <div nz-col nzSpan="24">
        <button nz-button nzType="primary" [nzSize]="'large'" (click)="addVideoShowCategory()">
            新建视频秀
        </button>
    </div>
</div>
<div nz-row style="margin-top: 30px">
    <div nz-col nzSpan="6" style="padding-top: 30px">
        <app-video-show-resource-category
                (categoryClickEvent)="categoryClicked($event)"></app-video-show-resource-category>
    </div>
    <div nz-col nzSpan="1">
        <nz-divider nzType="vertical" style="height: 800px;"></nz-divider>
    </div>
    <div nz-col nzSpan="17">
        <app-video-show-resource-table
                *ngIf="displayVideoShowResourceTable"
                [category_id]="videoShowCategoryId"
                [readonly]="videoShowCategoryReadonly"
                (openResourceFormEvent)="openVideoShowResourceForm($event)">
        </app-video-show-resource-table>

        <app-video-show-resource-form
                *ngIf="!displayVideoShowResourceTable"
                [videoShowResourceId]="videoShowResourceId"
                [categoryId]="videoShowCategoryId"
                (goBackEvent)="displayVideoShowResourceTable=true"></app-video-show-resource-form>
    </div>
</div>
