import {Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges} from '@angular/core';
import {UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import {VideoShowResourceService} from '../../../../../../../services/video-show-resource.service';
import {NzMessageService} from 'ng-zorro-antd/message';
import {Subscription} from 'rxjs';

@Component({
    selector: 'app-video-show-resource-form',
    templateUrl: './video-show-resource-form.component.html',
    styleUrls: ['./video-show-resource-form.component.css']
})
export class VideoShowResourceFormComponent implements OnChanges, OnDestroy {

    @Input() categoryId!: number;
    @Input() videoShowResourceId?: number;
    @Output() goBackEvent = new EventEmitter<void>();

    private subscriptions: Subscription = new Subscription();

    validateForm: UntypedFormGroup;
    resourceName = '';

    constructor(private fb: UntypedFormBuilder,
                private videoShowResourceService: VideoShowResourceService,
                private msg: NzMessageService) {
        this.validateForm = this.fb.group({
            name: ['', [Validators.required]],
            content: ['', [Validators.required]],
        });
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (!changes['videoShowResourceId']) {
            return;
        }

        this.validateForm.reset();
        if (this.videoShowResourceId) {
            // load the resource info to update it
            this.subscriptions.add(
                this.videoShowResourceService.queryById(this.videoShowResourceId).subscribe(res => {
                    this.resourceName = res.name;
                    this.validateForm.controls['name'].setValue(res.name);
                    this.validateForm.controls['content'].setValue(res.content);
                })
            );
        }
    }

    goBack(): void {
        this.goBackEvent.emit();
    }

    submitForm(): void {
        if (this.videoShowResourceId) {
            this.subscriptions.add(
                this.videoShowResourceService.edit({id: this.videoShowResourceId, ...this.validateForm.value})
                    .subscribe(() => this.goBack(), () => this.msg.error('修改视频秀资源失败，请联系管理员'))
            );
        } else {
            this.subscriptions.add(
                this.videoShowResourceService.add({category_id: this.categoryId, ...this.validateForm.value})
                    .subscribe(() => this.goBack(), () => this.msg.error('创建视频秀资源失败，请联系管理员'))
            );
        }
    }

    ngOnDestroy(): void {
        this.subscriptions.unsubscribe();
    }
}
