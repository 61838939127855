import {Component, Input, OnDestroy} from '@angular/core';
import {BookBarSetting} from '../../../../../../../model/BookBarSetting';

@Component({
    selector: 'app-book-bar-setting-preview',
    templateUrl: './book-bar-setting-preview.component.html',
    styleUrls: ['./book-bar-setting-preview.component.scss']
})
export class BookBarSettingPreviewComponent{

    @Input() setting?: BookBarSetting;

}
