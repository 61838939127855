// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import { Component, OnInit } from '@angular/core';
import { UserService } from '../../../services/user.service';
import { ActivatedRoute, Router } from '@angular/router';
import { NzMessageService } from 'ng-zorro-antd/message';
import { Const } from '../../../config/const';
import { UserType } from '../../../model/Const';

@Component({
  selector: 'app-admin-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class AdminLoginComponent implements OnInit {
  loginLabel: string;
  user: any;
  btnDisable: boolean;

  constructor(
    private userService: UserService,
    private activatedRoute: ActivatedRoute ,
    private router: Router ,
    private nzMessageService: NzMessageService,
  ) {
  }

  ngOnInit() {
    this.user = {
      phone: '',
      password: ''
    };
    this.loginLabel = 'Login';
  }

  onBtnLogin() {
    // this.btnDisable = true;
    // this.loginLabel = 'Loading...';
    this.userService.userLogin(this.user)
      .then(data => {
        if (data.type !== UserType.STUDENT) {
          return;
        }
        // const return_url = this.activatedRoute.snapshot.queryParams.return;
        // if (return_url) {
        //   this.router.navigate([return_url]);
        // } else {
        //   this.router.navigate(['/admin/classes']);
        // }
        this.router.navigate(['/admin/classes']);
      }).catch( error => {
      let msg = error.message || 'Something wrong';
      switch (error.errorCode) {
        case Const.ERROR_CODE.NO_USER:
          msg = '用户不存在或密码错误';
          break;
        case Const.ERROR_CODE.INVALID_PARAMS:
          msg = '请输入正确的用户名密码';
          break;
      }
      this.nzMessageService.error(msg);
    });
  }
}
