import {Component, OnDestroy} from '@angular/core';
import {BookBarSetting} from '../../../../../../model/BookBarSetting';

@Component({
    selector: 'app-book-bar-setting',
    templateUrl: './book-bar-setting.component.html',
    styleUrls: ['./book-bar-setting.component.scss']
})
export class BookBarSettingComponent {

    setting?: BookBarSetting;

    settingChanged(setting: BookBarSetting): void {
        this.setting = setting;
    }
}
