<nz-tree-view [nzTreeControl]="treeControl" [nzDataSource]="dataSource" [trackBy]="trackBy">
    <!--新建节点-->
    <nz-tree-node *nzTreeNodeDef="let node; when: isAdding" nzTreeNodeIndentLine>
        <input nz-input placeholder="请输入名称" nzSize="small" #inputElement/>
        &nbsp;
        <button nz-button nzType="text" nzSize="small" (click)="saveNewCategory(node, inputElement.value)">
            <span nz-icon nzType="check" nzTheme="outline"></span>
        </button> &nbsp;
        <button nz-button nzType="text" nzSize="small" (click)="cancelAddingCategory(node)">
            <span nz-icon nzType="close" nzTheme="outline"></span>
        </button>
    </nz-tree-node>

    <!--编辑节点-->
    <nz-tree-node *nzTreeNodeDef="let node; when: isEditing" nzTreeNodeIndentLine>
        <input nz-input [(ngModel)]="node.name" nzSize="small" #inputElement/>
        &nbsp;
        <button nz-button nzType="text" nzSize="small" (click)="saveExistingCategory(node, inputElement.value)">
            <span nz-icon nzType="check" nzTheme="outline"></span>
        </button> &nbsp;
        <button nz-button nzType="text" nzSize="small" (click)="cancelEditingCategory(node)">
            <span nz-icon nzType="close" nzTheme="outline"></span>
        </button>
    </nz-tree-node>

    <!--root节点不可选中，可以删除或者编辑当前节点，可以添加二级节点-->
    <nz-tree-node *nzTreeNodeDef="let node; when: isRootLevel" nzTreeNodeIndentLine>
        <nz-tree-node-toggle>
            <span nz-icon nzType="caret-down" nzTreeNodeToggleRotateIcon></span>
        </nz-tree-node-toggle>
        <nz-tree-node-option (nzClick)="treeControl.toggle(node)">
            <h3>{{ node.name }} &nbsp;</h3>
        </nz-tree-node-option>

        <button *ngIf="!node.readonly" nz-button nzType="text" nzSize="small" (click)="addChildCategory(node)">
            <span nz-icon nzType="plus" nzTheme="outline"></span>
        </button>
        <button *ngIf="!node.readonly" nz-button nzType="text" nzSize="small" nz-popconfirm nzPopconfirmTitle="删除后无法恢复，确认删除吗？" (nzOnConfirm)="deleteCategory(node)">
            <span nz-icon nzType="minus" nzTheme="outline"></span>
        </button>
        <button *ngIf="!node.readonly" nz-button nzType="text" nzSize="small" (click)="editCategory(node)">
            <span nz-icon nzType="edit" nzTheme="outline"></span>
        </button>
    </nz-tree-node>

    <!--二级节点可选中，此时右侧表格显示其下的资源，可以删除或修改二级节点，不可添加三级节点-->
    <nz-tree-node *nzTreeNodeDef="let node; when: isNoneRootLevel" nzTreeNodeIndentLine>
        <nz-tree-node-option
                [nzSelected]="selectListSelection.isSelected(node)"
                (nzClick)="loadVideoShowResourceTable(node)"
        >
            <h4>{{ node.name }} &nbsp;</h4>
        </nz-tree-node-option>
        <button *ngIf="!node.readonly" nz-button nzType="text" nzSize="small" nz-popconfirm nzPopconfirmTitle="删除后无法恢复，确认删除吗？" (nzOnConfirm)="deleteCategory(node)">
            <span nz-icon nzType="minus" nzTheme="outline"></span>
        </button>
        <button *ngIf="!node.readonly" nz-button nzType="text" nzSize="small" (click)="editCategory(node)">
            <span nz-icon nzType="edit" nzTheme="outline"></span>
        </button>
    </nz-tree-node>

</nz-tree-view>
