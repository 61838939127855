import {Component, OnDestroy} from '@angular/core';
import {Subscription} from 'rxjs';
import {PaginationSearchResult} from '../../../../../../model/PaginationSearchResult';
import {NzMessageService} from 'ng-zorro-antd/message';
import {BookBarEnrollmentService} from '../../../../../../services/book-bar-enrollment.service';
import {BookBarEnrollment} from '../../../../../../model/BookBarEnrollment';
import {NzTableQueryParams} from 'ng-zorro-antd/table';

@Component({
    selector: 'app-book-bar-enrollment',
    templateUrl: './book-bar-enrollment.component.html',
    styleUrls: ['./book-bar-enrollment.component.scss']
})
export class BookBarEnrollmentComponent implements OnDestroy {
    private subscriptions: Subscription = new Subscription();

    filterTracked = [
        {text: '未跟进', value: 0},
        {text: '已跟进', value: 1}
    ];
    pageSize = 10;
    currentFilters?: { key: string; value: any; }[];
    currentPage = 1;

    searchResult: PaginationSearchResult<BookBarEnrollment> = {
        rows: [],
        total: 0,
        displayCount: 0,
        pageNum: 1,
        start: 0,
        end: 0,
        currentPage: 1
    };

    constructor(private bookBarEnrollmentService: BookBarEnrollmentService,
                private msg: NzMessageService) {
        this.loadData();
    }

    onQueryParamsChange(params: NzTableQueryParams): void {
        this.currentFilters = params.filter;
        this.loadData();
    }

    onPageIndexChange(page: number): void {
        this.currentPage = page;
        this.loadData();
    }

    loadData(): void {
        this.subscriptions.add(
            this.bookBarEnrollmentService.queryByPage(this.currentPage, this.currentFilters)
                .subscribe(result => {
                    this.searchResult = result;
                }, () => this.msg.error('加载失败，请联系管理员'))
        );
    }

    markAsTracked(id: number) {
        this.subscriptions.add(
            this.bookBarEnrollmentService.markAsTracked(id)
                .subscribe(() => this.loadData())
        );
    }

    ngOnDestroy(): void {
        this.subscriptions.unsubscribe();
    }
}
